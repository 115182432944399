import $ from 'cash-dom';
import throttle from 'raf-throttle';
import viewportBuggyfill from 'viewport-units-buggyfill';

let count = 0;
setInterval(() => {
  count = count > 9 ? 0 : count;
  $('#hero h1 span.text').css('top', `-${count * 1.5}em`);
  count++;
}, 2000);

viewportBuggyfill.init();

$('[data-modal]').on('click', e => {
  viewportBuggyfill.refresh();
  const modalId = $(e.target).data('modal');
  $(`#${modalId}`)
    .addClass('visible')
    .find('modal-content')
    .css('padding', '100px 32px;');
  $(document.body).addClass('modal-visible');
});

$('[data-close-modal]').on('click', e => {
  $(e.target)
    .closest('.modal')
    .removeClass('visible')
    .find('modal-content')
    .css('padding', 'unset');
  $(document.body).removeClass('modal-visible');
});

$('.option input[type=checkbox]').on('change', function() {
  $(this)
    .closest('.option')
    .toggleClass('selected');
});

$('.modal').on('scroll', e => e.stopPropagation());

const distances = [];

const onScroll = throttle(() => {
  $('#sections-container section').each((index, section) => {
    distances[index] = Math.abs(
      section.offsetTop - $('aside')[0].offsetTop
    );
  });

  $('#sections-container .section-name').text(
    $($('#sections-container section')[distances.indexOf(Math.min(...distances))]).data(
      'display-name'
    )
  );

  if ($('#cta')[0].offsetTop > window.innerHeight) {
    $('#cta').addClass('small');
  } else {
    $('#cta').removeClass('small');
  }
});

$(window).on('scroll', onScroll);

$(window).on('resize', () => {
  $('#cta').css({ top: window.innerHeight - 92 });
});
